import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import App from './app';

import './heap-onload';

ReactDOM.render(
  <HttpsRedirect disabled={process.env.REACT_APP_HTTPS_ENFORCEMENT !== 'true'}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HttpsRedirect>,
  document.getElementById('root')
);
