import React from 'react';
import { Helmet } from 'react-helmet';
import Buffer from 'buffer/';
import { ChakraProvider, extendTheme, VStack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { QueryClient, QueryClientProvider } from 'react-query';
import Theme, { chakraTheme } from './theme/theme';
import { Auth0Provider } from './contexts/auth0';
import { TopLevelViews } from './views/top-level-views';
import MembersErrorBoundary from './members-error-boundary';
import { getMetaDescriptionTag } from './utils/meta-tags';

global.Buffer = Buffer.Buffer;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = extendTheme(chakraTheme);

if (global.APP.RUNTIME !== 'development') {
  datadogLogs.init({
    clientToken: 'pub26c267ceb8abc166ed08d7537abb4117',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.REACT_APP_GIT_SHA,
    service: 'membership-browser',
  });
}

function App() {
  return (
    <MembersErrorBoundary>
      <ChakraProvider theme={theme}>
        <Theme>
          <VStack minH={'100vh'}>
            <QueryClientProvider client={queryClient}>
              <Helmet>
                <meta name="description" content={getMetaDescriptionTag('Member Portal')} />
              </Helmet>
              <Auth0Provider>
                <TopLevelViews />
              </Auth0Provider>
            </QueryClientProvider>
          </VStack>
        </Theme>
      </ChakraProvider>
    </MembersErrorBoundary>
  );
}

export default App;
