import React from 'react';
import GridWrapper from './grid-wrapper';
import { Box, Link } from '@chakra-ui/react';
import { DateTime } from 'luxon';

export function Banner({ mainCopy, linkCopy, linkHref }) {
  return (
    <Box role="banner" display="flex" fontSize="sm" bg="bannerGray" justifyContent="center" height="3rem">
      <GridWrapper>
        <Box w="full" py={3}>
          {mainCopy} -{' '}
          <Link textDecoration="underline" href={linkHref} color="pink">
            {linkCopy}
          </Link>
        </Box>
      </GridWrapper>
    </Box>
  );
}

const GRANT_START_DATETIME = DateTime.fromObject(
  { year: 2023, month: 3, day: 13, hour: 0 },
  { zone: 'America/Los_Angeles' }
);

const GRANT_END_DATETIME = DateTime.fromObject(
  { year: 2023, month: 3, day: 25, hour: 23, minute: 59, second: 59 },
  { zone: 'America/Los_Angeles' }
);

const now = DateTime.now();
// enable testing prior to start date
const TESTING_OVERRIDE = localStorage.getItem('ENABLE_GRANT_BANNER_OVERRIDE');

const GRANT_BANNER_IS_ENABLED = TESTING_OVERRIDE || (now >= GRANT_START_DATETIME && now <= GRANT_END_DATETIME);

export function GrantProgramBanner() {
  if (GRANT_BANNER_IS_ENABLED) {
    return (
      <Banner
        mainCopy={'The Venice Grant Program is underway!'}
        linkCopy={'Apply here by March 25th.'}
        linkHref={'https://info.venicemusic.co/member-grant-application'}
      />
    );
  }

  return null;
}
