import WalletContext from '../contexts/wallet-context';
import Auth0Context from '../contexts/auth0';
import { useContext } from 'react';

const ONE_YEAR_SECONDS = 365 * 24 * 60 * 60;

//https://venice-music.atlassian.net/browse/VP-3227
export const extendGenesisPassRenewalDate = thingThatMayHaveNFTs => {
  if (!thingThatMayHaveNFTs.membershipNFTs) {
    return thingThatMayHaveNFTs;
  }

  return {
    ...thingThatMayHaveNFTs,
    membershipNFTs: thingThatMayHaveNFTs.membershipNFTs.map(nft => {
      nft.renewalDate = nft.renewalDate + ONE_YEAR_SECONDS;

      return nft;
    }),
  };
};

export function useUser() {
  const { subscriptionQuery } = useContext(WalletContext);
  const { loggedInSubscriptionMembershipQuery } = useContext(Auth0Context);

  if (loggedInSubscriptionMembershipQuery.isSuccess && loggedInSubscriptionMembershipQuery.data) {
    return {
      user: extendGenesisPassRenewalDate(loggedInSubscriptionMembershipQuery.data),
      refetchUser: loggedInSubscriptionMembershipQuery.refetch,
    };
  }

  if (subscriptionQuery.isSuccess && subscriptionQuery.data) {
    return { user: extendGenesisPassRenewalDate(subscriptionQuery.data), refetchUser: subscriptionQuery.refetch };
  }

  return { user: null, refetchUser: () => null };
}
