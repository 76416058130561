function loadHeap(e, t) {
  (window.heap.appid = e), (window.heap.config = t = t || {}); // eslint-disable-line no-unused-expressions
  let r = document.createElement('script');
  (r.type = 'text/javascript'), (r.async = !0), (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js'); // eslint-disable-line no-unused-expressions
  let a = document.getElementsByTagName('script')[0];
  a.parentNode.insertBefore(r, a);
  const p = [
    'addEventProperties',
    'addUserProperties',
    'clearEventProperties',
    'identify',
    'resetIdentity',
    'removeEventProperty',
    'setEventProperties',
    'track',
    'unsetEventProperty',
  ];

  const n = e => () => heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));

  for (let o = 0; o < p.length; o++) {
    heap[p[o]] = n(p[o]);
  }
}
function loadShim() {
  const oldHeap = window.heap;

  window.heap = new Proxy(oldHeap, {
    get(target, prop) {
      return function () {};
    },
  });
}

(window.heap = window.heap || []), // eslint-disable-line no-unused-expressions
  (heap.load = ['production'].includes(window.APP.RUNTIME) ? loadHeap : loadShim);

heap.load(process.env.REACT_APP_HEAP_MEMBERSHIP_APP_ID);
