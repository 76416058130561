import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { Button, Flex, Text } from '@chakra-ui/react';
import { icons } from './theme/icons';
const { ExternalLink } = icons;

const containerStyle = {
  direction: 'column',
  justify: 'flex-start',
  width: { base: '100%', md: 'calc((100% - 2rem) / 2)', lg: 'calc((100% - 4rem) / 3)' },
};

if (global.APP.RUNTIME !== 'development') {
  datadogLogs.init({
    clientToken: 'pub26c267ceb8abc166ed08d7537abb4117',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.REACT_APP_GIT_SHA,
    service: 'membership-browser',
  });
}

function AlertFlex({ text, children }) {
  return (
    <Flex {...containerStyle} role={'alert'} style={{ margin: 'auto', width: '50%', padding: '20px' }}>
      <Text mb="0.5rem" variant="itemTitle" noOfLines={2} style={{ margin: 'auto', width: '100%', padding: '20px' }}>
        {text}
      </Text>
      <div style={{ padding: '5px', margin: 'auto', width: '50%' }}>{children}</div>
    </Flex>
  );
}

export default class MembersErrorBoundary extends React.Component {
  static defaultProps = {
    variant: 'hidden',
  };

  static variants = {
    HIDDEN: 'hidden',
    SKIP_A_BEAT: 'skip_a_beat',
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, attempts: 0, spinning: false };
  }

  componentDidCatch(error, info) {
    datadogLogs.logger.info('Error boundary hit', {
      pathname: window?.location?.pathname,
      error,
      info,
    });

    this.setState(p => ({
      ...p,
      errorState: {
        error,
        info,
      },
    }));
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  get variant() {
    return this.props.variant || MembersErrorBoundary.defaultProps.variant;
  }

  get shouldHide() {
    return this.variant === MembersErrorBoundary.variants.HIDDEN && this.state.hasError;
  }

  get shouldSkipABeat() {
    return this.variant !== MembersErrorBoundary.variants.HIDDEN && this.state.hasError;
  }

  render() {
    if (this.shouldHide) {
      if (window.APP.RUNTIME === 'development') {
        return (
          <div style={{ whiteSpace: 'pre-line' }}>
            <pre>
              Error: {JSON.stringify(this.state.errorState?.error, null, 4)}
              {this.state.errorState?.info?.componentStack}
            </pre>
          </div>
        );
      }

      return <></>;
    }

    if (this.shouldSkipABeat) {
      return (
        <AlertFlex text={'Looks like we skipped a beat.'}>
          <Button onClick={() => (window.location = 'https://support.venice.tech/venicemusiccollective/')}>
            Visit Support &nbsp; <ExternalLink mx="2px" />
          </Button>
        </AlertFlex>
      );
    }

    return this.props.children;
  }
}
