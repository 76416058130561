import React, { createContext, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useGetSubscriptionMembershipFromWallet } from '../data-client/subscription-membership';
import ENUMS from '../constants/HEAP.gen.json';

const WalletContext = createContext();
export default WalletContext;

export function WalletConnectionProvider({ children }) {
  const { account: walletAddress, error: web3ReactError, isActive: active, chainId, library } = useWeb3React();

  const oldWeb3ReactError = useRef();
  const oldActive = useRef(active);

  if (web3ReactError && JSON.stringify(oldWeb3ReactError.current) !== JSON.stringify(web3ReactError)) {
    oldWeb3ReactError.current = web3ReactError;
    heap.track(ENUMS.MEMBERSHIP.CONNECT_WALLET.NAME, {
      type: ENUMS.MEMBERSHIP.CONNECT_WALLET.TYPE.CONNECT_ERROR,
      errorName: web3ReactError.name,
      errorMessage: web3ReactError.message,
    });
  }
  if (active && oldActive.current !== active) {
    oldActive.current = active;
    heap.track(ENUMS.MEMBERSHIP.CONNECT_WALLET.NAME, {
      type: ENUMS.MEMBERSHIP.CONNECT_WALLET.TYPE.CONNECT_SUCCESS,
      chainId,
      connectionURL: library?.connection?.url,
    });
  }

  const subscriptionQuery = useGetSubscriptionMembershipFromWallet(walletAddress);

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        subscriptionQuery,
      }}>
      {children}
    </WalletContext.Provider>
  );
}
