const PROFILE = 'profile';
const PAYMENT = 'payment';

export const ROUTE_PATHS = {
  ROOT: '/',
  LOGIN: '/login',
  INITIAL_LOGIN: '/login-initial/:tier',
  SIGNUP: {
    ROOT: '/signup',
    PROFILE,
    PAYMENT,
  },
  LEGACY_SIGNUP: '/nft-signup',
  SIGNIN: '/signin',
  CLAIM: '/claim',
  MEMBER_HOMEPAGE: '/home',
  LOGGED_IN_HOMEPAGE: '/homepage',
  STRIPE_PAYMENT: '/payment',
  STRIPE_RETURN_CALLBACK: '/payment/redirect',
  NO_NFT_ERROR: '/noNft',
  SETTINGS: {
    ROOT: '/settings',
    PROFILE,
    PAYMENT,
  },
};
