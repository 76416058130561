/**
 * Tier intent
 * @typedef {Object} tier
 * @property {string} tierId
 * @property {string} tierName
 * @property {Number} price
 * @property {Number} couponPrice
 * @property {boolean} visible
 */

/**
 * Serialize tier as DataLayer item
 *
 * @param {tier[]|tier} tiers - Tier or Array of Tiers
 * @param {string} couponCode - Any coupon code applied to the item
 * @param {string} currency - 'USD' or 'USDC'
 * @return {Array<{item_id,item_name,index,currency,price,quantity,coupon,discount}>}
 */
function tiersToItems(tiers, couponCode, currency) {
  return (Array.isArray(tiers) ? tiers : [tiers]).map((t, index) => ({
    item_id: t.tierId,
    item_name: t.tierName,
    index,
    currency: currency,
    price: t.price,
    quantity: 1,
    coupon: couponCode,
    discount: t.couponPrice ? t.price - t.couponPrice : 0,
  }));
}

/**
 * Store information about a list of tiers being shown to the user in the DataLayer
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
 * @export
 * @param {tier[]} tiers
 * @param {string} couponCode
 */
export function viewItemListEvent(tiers, couponCode) {
  const payload = {
    items: tiersToItems(
      tiers.filter(tier => tier.visible),
      couponCode,
      'USD'
    ),
  };

  // GTM
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: payload,
  });

  // Segment
  window.analytics.track('view_item_list', payload);
}
/**
 * Store information about the selected tier in the DataLayer
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item
 * @export
 * @param {tier} tier - Currently selected tier object/intent
 * @param {string} couponCode - Any coupon applied to the cart at the time
 */
export function selectItemEvent(tier, couponCode) {
  const payload = {
    items: tiersToItems(tier, couponCode, 'USD'),
  };

  // GTM
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: payload,
  });

  // Segment
  window.analytics.track('select_item', payload);
}
/**
 * Store information about checkout event in the DataLayer
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
 *
 * @export
 * @param {tier} tier - Selected tier
 * @param {string} couponCode - Any coupon code applied to the tier
 * @param {string} currency - Currency (USD or USDC)
 */
export function beginCheckoutEvent(tier, couponCode, currency) {
  const payload = {
    currency: currency,
    value: tier.couponPrice,
    coupon: couponCode,
    items: tiersToItems(tier, couponCode, currency),
  };

  // GTM
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: payload,
  });

  // Segment
  window.analytics.track('begin_checkout', payload);
}

/**
 * Store purchase information in DataLayer
 *
 * @export
 * @param {tier} tier - Selected tier
 * @param {string} couponCode - Any coupon code applied to the tier
 * @param {string} currency - Currency (USD or USDC)
 * @param {string} transactionId - Id of stripe transaction
 */
export function purchaseEvent(tier, couponCode, currency, transactionId) {
  const payload = {
    currency: currency,
    value: tier.couponPrice || tier.price,
    coupon: couponCode,
    transaction_id: transactionId,
    items: tiersToItems(tier, couponCode, currency),
  };

  // GTM
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: payload,
  });

  // Segment
  window.analytics.track('purchase', payload);
}

/**
 * Store customer information in DataLayer.
 *
 * @export
 * @param {string} name - Full name
 * @param {string} email - Email
 * @param {string} subscriptionId - Stripe customer id
 */
export function kycEvent(name, email, subscriptionId) {
  window.dataLayer.push({ kyc: null });
  window.dataLayer.push({
    kyc: {
      name,
      email,
      id: subscriptionId,
    },
  });
}

/**
 * Store form information in DataLayer.
 *
 * @export
 * @param {string} formName
 * @param {"submit"|"start"|"error"|"input"|"page_submit"} event
 * @param {{field_name: string, field_value: string} | {form_page: string, form_step_number: number} | { form_page: string, error_message: string}} [data]
 */
export function formEvent(formName, event, data) {
  const payload = Object.assign(
    {},
    {
      form_name: formName,
    },
    data
  );

  window.dataLayer.push({ form: null });
  window.dataLayer.push({
    event: `form_${event}`,
    form: payload,
  });

  // Segment
  window.analytics.track(`form_${event}`, payload);
}

/**
 * Whenever we know who the customer is, we call idenfity to associate analytics.
 *
 * @export
 * @param {number} userId - User id
 * @param {string} email - User email
 */
export function identify(userId, email) {
  // GTM
  window.dataLayer.push({ identify: null });
  window.dataLayer.push({
    event: 'identify',
    login: {
      email,
    },
  });

  // Segment
  window.analytics.identify(userId, {
    email,
  });
}

/**
 * Store login information in DataLayer.
 *
 * @export
 * @param {string} email
 */
export function login(email) {
  const payload = {
    email,
  };

  // GTM
  window.dataLayer.push({ login: null });
  window.dataLayer.push({
    event: 'login',
    login: payload,
  });

  // Segment
  window.analytics.track('login', payload);
}

/**
 * Store login information in DataLayer.
 *
 * @export
 * @param {string} email
 */
export function logout(email) {
  const payload = {
    email,
  };

  window.dataLayer.push({ logout: null });
  window.dataLayer.push({
    event: 'logout',
    login: payload,
  });

  // Segment
  window.analytics.track('logout', payload);
}
