import React from 'react';
import { GridItem, Grid } from '@chakra-ui/react';

const GridWrapper = props => {
  const { gridItem, ...gridProps } = props;

  return (
    <Grid
      templateColumns={{ base: 'auto calc(100% - 48px) auto', lg: '1fr min(66.6%, 960px) 1fr' }}
      width="100%"
      {...gridProps}>
      <GridItem className="grid-wrapper-left"></GridItem>
      <GridItem {...gridItem}>{props.children}</GridItem>
      <GridItem className="grid-wrapper-right"></GridItem>
    </Grid>
  );
};

export default GridWrapper;
