import DATA_API_URL from './data-api-url';
import { get, post } from 'axios';
import sharedRequestOptions from './shared-request-options';
import { useMutation, useQuery } from 'react-query';
import { extendGenesisPassRenewalDate } from './user';

const setHeapIdentity = userEmail => {
  window.heap && userEmail && window.heap.identify(userEmail);
};

export const useGetSubscriptionMembershipFromWallet = walletAddress => {
  return useQuery(
    ['getSubscriptionMembership', walletAddress],
    async () => {
      const requestOptions = sharedRequestOptions({ walletAddress });

      const resp = await get(`${DATA_API_URL}/subscriptionMemberships`, {
        ...requestOptions,
      }).catch(err => {
        if (err.response.status === 401) {
          return { data: { data: { requiresSignature: true } } };
        }
        throw err;
      });
      setHeapIdentity(resp?.data?.data?.user?.email);
      return resp;
    },
    {
      enabled: !!walletAddress,
      refetchOnWindowFocus: false,
      select: res => extendGenesisPassRenewalDate(res?.data?.data),
    }
  );
};

export const useGetSubscriptionMembership = auth0Token => {
  const token = auth0Token || localStorage.getItem('scale_music_auth0_token');
  return useQuery(
    ['getSubscriptionMembership', token],
    async () => {
      //the routing handles
      const resp = await get(`${DATA_API_URL}/subscriptionMemberships`, sharedRequestOptions());
      setHeapIdentity(resp?.data?.data?.user?.email);
      return resp;
    },
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      retry(failures, error) {
        if (!!token && failures >= 3 && error?.response?.status === 401) {
          //if there is an error, the token could be bad or good ...
          //but either way removing the token will not be determental. Worst case scenerio
          //the user has to login again.
          // DO NOT: redirect the user from here, that's handled by the routing logic in src/views/route-permission-enforcers.jsx && src/views/views.js
          localStorage.removeItem('scale_music_auth0_token');
          return false;
        }
        return token || error.response.status !== 401;
      },
      select: res => extendGenesisPassRenewalDate(res?.data?.data),
    }
  );
};

//Sends request to make a stripe customer with the email and address.
//will return a flag if email is already associated with an active stripe subscription.
export const usePostCustomer = options =>
  useMutation(async body => {
    const resp = await post(`${DATA_API_URL}/customer`, body);
    setHeapIdentity(resp?.data?.data.email);
    return resp;
  }, options);

export const usePostCustomerNewMembership = options =>
  useMutation(async body => await post(`${DATA_API_URL}/customer/new-membership`, body), options);

export const useGetCustomerSession = ({ stripeSessionId }) => {
  return useQuery('get-session', () => get(`${DATA_API_URL}/customer/session`, { params: { stripeSessionId } }), {
    select: res => res?.data.data,
  });
};

export const useGetStripeTiers = ({ walletAddress, discountId }) => {
  const requestOptions = sharedRequestOptions({ walletAddress });
  requestOptions.params = { ...requestOptions.params, discountId };
  return useQuery('membership-tiers', () => get(`${DATA_API_URL}/tiers`, { ...requestOptions }), {
    select: res => res?.data.data.results,
  });
};

export const useSetSubscriptionSelectedAt = ({ customerId }, options = {}) =>
  useMutation(
    ({ subscriptionId, tierName }) => {
      return post(`${DATA_API_URL}/customer/${customerId}/${subscriptionId}/${tierName}`, {});
    },
    {
      ...options,
    }
  );

//TODO with the new flow, this may not be needed
export const usePostSubscriptionMutation = (walletAddress, optionsOverride = {}) =>
  // creating membership will redirect to homepage
  // and getSubscriptionMembership will be called to call setHeapIdentity.
  useMutation(
    body =>
      post(`${DATA_API_URL}/subscriptionMemberships`, body, {
        ...sharedRequestOptions({ walletAddress }),
      }),
    optionsOverride
  );

export const useGetAllTiersPaymentIntents = ({ customerId, discountId }, options = {}) => {
  return useQuery(
    ['all-tiers-payment-intents'],
    () => get(`${DATA_API_URL}/customer/${customerId}/intents`, { params: { discountId } }),
    {
      ...options,
      placeholderData: {
        data: { data: { intents: [] } },
      },
      select: data => {
        return data?.data?.data?.intents?.map(tier => tier?.data) ?? [];
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
