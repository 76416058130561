import { Icon } from '@chakra-ui/react';
import React from 'react';

import {
  RiCheckboxCircleFill as checkboxCircleFill,
  RiCheckboxCircleLine as checkboxCircleLine,
  RiAlertFill as alertFill,
  RiInformationLine as informationNonFill,
  RiArrowRightUpLine as arrowRightUpLine,
  RiArrowRightLine as arrowRightLine,
  RiCloseFill as closeFill,
  RiExternalLinkLine as ExternalLink,
} from 'react-icons/ri';

export const icons = {
  checkboxCircleFill,
  checkboxCircleLine,
  alertFill,
  informationNonFill,
  arrowRightUpLine,
  closeFill,
  ExternalLink,
};

export const styledIcons = {
  greenCheckboxCircleFill: props => {
    return <Icon as={checkboxCircleFill} color={'green'} {...props} />;
  },
  greenCheckboxCircleLine: props => {
    return <Icon as={checkboxCircleLine} color={'green100'} {...props} />;
  },
  orangeAlertFill: props => {
    return <Icon as={alertFill} color={'orange.300'} {...props} />;
  },
  redAlertFill: props => {
    return <Icon as={alertFill} color={'red.150'} {...props} />;
  },
  whiteArrowRightUpLine: props => {
    return <Icon as={arrowRightUpLine} color={'white'} {...props} />;
  },
  whiteArrowRightLine: props => {
    return <Icon as={arrowRightLine} color={'white'} {...props} />;
  },
  grayinformationNonFill: props => {
    return <Icon as={informationNonFill} color={'gray.500'} {...props} />;
  },
  blackCloseFill: props => {
    return <Icon as={closeFill} color={'black100'} {...props} />;
  },
  arrowDown: props => {
    return (
      <Icon fill="none" height="66" viewBox="0 0 16 66" width="16" {...props}>
        <path
          d="m9 1c0-.552285-.44772-1.00000002-1-1s-1 .447715-1 1zm-1.7071 64.7071c.39052.3905 1.02369.3905 1.41421 0l6.36399-6.364c.3905-.3905.3905-1.0236 0-1.4142-.3906-.3905-1.0237-.3905-1.4142 0l-5.6569 5.6569-5.65685-5.6569c-.39053-.3905-1.02369-.3905-1.414215 0-.390525.3906-.390525 1.0237 0 1.4142zm-.2929-64.7071v64h2v-64z"
          fill="#fff"
        />
      </Icon>
    );
  },
};
