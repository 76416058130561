import MEMBERSHIP from '../constants/MEMBERSHIP.gen.json';

//if there is a wallet, use it for authoriziation. Otherwise use the token
function getAuth0header() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('scale_music_auth0_token')}` },
    params: { authtype: 'auth0' },
  };
}
const sharedMembershipRequestOptions = ({ walletAddress } = {}, params) => {
  const options = walletAddress
    ? { headers: { [MEMBERSHIP.headers.WALLET_ADDRESS]: walletAddress }, params: { authtype: 'wallet', ...params } }
    : getAuth0header();

  return {
    withCredentials: true,
    ...options,
  };
};

export default sharedMembershipRequestOptions;
