import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Fonts } from './fonts';
import { icons, styledIcons } from './icons';

export const colors = {
  black: '#000000',
  black110: '#151515',
  black100: '#222028',
  black90: '#2D2A38',
  black80: '#413E4D',
  black70: '#565263',
  black60: '#686573',
  black50: '#9A98A0',
  black50WithOpacity: 'rgba(154,152,160,0.10)',
  cream100: '#EEE6DB',
  cream200: '#D2CFCA',
  green100: '#78CB0E',
  orange50: '#A65D28',
  orange100: '#DA7E3C',
  orange200: '#FF7C1E',
  orange300: '#DF6A38',
  red100: '#F5513A',
  red150: '#D45656',
  yellow100: '#F4F431',
  blue100: '#559AEB',
  white: '#FFFFFF',
  pink: '#FF335F',
  gray30: '#F3F3F3',
  gray: '#D2CFCA',
  purple100: '#7A5BF8',
  bannerGray: '#F0EFF1',
};

const globalStyles = {
  body: {
    color: colors.black100,
  },
};

const theme = {
  colors: {
    brand: {
      layer0: colors.black100,
      layer1: colors.black90,
      layer2: colors.black80,
      layer3: colors.black60,
      layer4: colors.black50,
      alertYellow: colors.yellow100,
      accent: colors.orange100,
      accentHover: colors.orange200,
      emphasis: '#2B2933',
      buttonBackground: colors.black70,
      link: colors.orange100,
    },
    background: {
      button: {
        outline: {
          default: 'transparent',
          borderColor: colors.black100,
          hover: colors.black90,
        },
      },
    },
    ...colors,
  },
};

export const chakraTheme = {
  ...theme,
  styles: {
    global: globalStyles,
  },
  icons,
  styledIcons,
  colors: {
    ...theme.colors,
    // override some colors with our own tokens
    gray: {
      30: '#F3F3F3',
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: colors.black50,
      //this color has to have this opacity or it really screws with my components text.
      550: colors.black50WithOpacity,
      600: colors.black60,
      700: colors.black70,
      800: colors.black80,
      900: colors.black90,
    },
    orange: {
      50: '#FFFAF0',
      100: '#FEEBC8',
      200: '#FBD38D',
      300: '#F6AD55',
      400: colors.orange200,
      500: colors.orange100,
      600: colors.orange50,
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    red: {
      150: colors.red150,
    },
    // new palette for our cream colors
    cream: {
      100: colors.cream100,
      200: colors.cream200,
    },
    purple: {
      100: colors.purple100,
    },
    success: colors.green100,
    error: colors.red100,
    warning: colors.yellow100,
    info: colors.blue100,
  },
  client_name: '[Client-specific overrides object here]',
  config: { initialColorMode: 'light' },
  fonts: {
    ...theme.fonts,
    body: 'Karla',
    heading: 'Neue Machina',
  },
  breakpoints: {
    ...theme.breakpoints,
    md: '40rem',
  },
  fontSizes: {
    xxxxlg: '2.75rem', // 44px
    xxxlg: '2.5rem', // 40px
    xxlg: '2rem', // 32px
    xlg: '1.5rem', // 24px
    lg: '1.125rem', // 18px
    reg: '1rem', // 16px
    sm: '0.875rem', // 14px
    xsm: '0.75rem', // 12px
    xxsm: '0.6875rem', // 11px
  },
  components: {
    Heading: {
      variants: {
        pageHeader: {
          color: 'black100',
          fontFamily: 'Neue Machina',
          fontWeight: 700,
          lineHeight: { base: '1.93rem', md: '2.75rem' },
          fontSize: { base: 'xxxlg', lg: 'xxxxlg' }, // TODO: confirm sizes in figma
        },
      },
    },
    Text: {
      variants: {
        itemTitle: {
          fontFamily: 'Karla',
          fontSize: '0.875rem',
          fontWeight: '500',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          leadingTrim: 'both',
          color: 'pink',
        },
        subText: {
          fontFamily: 'Neue Machina',
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '133%',
          paddingTop: '1rem',
        },
        pageInstructions: {
          color: 'black',
          fontSize: { base: '1rem', md: '1.5rem' },
          lineHeight: { base: '1.5rem', md: '1.75375rem' },
          fontWeight: { base: 400, md: 700 },
        },
        helpLink: {
          color: 'black',
          fontFamily: 'Karla',
          fontWeight: 400,
        },
        modalHelp: {
          color: 'black50',
          textAlign: 'left',
          display: 'block',
          width: '100%',
        },
        boldRed: {
          color: 'pink',
          fontWeight: 'bold',
        },
        boldGreen: {
          color: 'green',
          fontWeight: 'bold',
        },
        boldGray: {
          color: 'gray',
          fontWeight: 'bold',
        },
      },
    },
    Link: {
      variants: {
        footer: {
          ml: { md: '3rem', base: '0' },
          color: 'black100', //possible change this?
          fontSize: '0.75rem',
          textAlign: 'center',
        },
        modalHelp: {
          color: 'black50',
        },
      },
    },
    Icon: {
      variants: {
        social: {
          color: 'pink',
          p: '1rem',
          height: '2rem',
          width: '2rem',
        },
      },
    },
    Input: {
      defaultProps: {
        size: null,
      },
      baseStyle: {
        field: {
          borderColor: 'black',
          borderRadius: '0.25rem',
          height: '2.75rem',
          pl: '1.4rem',
          fontSize: '1.125rem',
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderColor: 'black',
          borderRadius: '0.25rem',
          pl: '1.4rem',
          fontSize: '1.125rem',
        },
      },
    },
    Button: {
      py: '0.25rem',
      px: '0.5rem',
      variants: {
        wallet: {
          bg: 'black100',
          borderRadius: '3px',
          color: 'white',
          fontSize: '1rem',
          fontWeight: 'normal',
        },
        'full-modal': {
          bg: 'black100',
          borderRadius: '6px',
          color: 'white',
          fontSize: '1rem',
          fontWeight: 'normal',
          width: '100%',
          height: '3.125rem',
        },
        dark: {
          bg: colors.black100,
          color: 'white',
          borderRadius: '0.375rem',
          fontWeight: 400,
          height: '3.125rem',
        },
        light: {
          border: `2px solid ${colors.black100}`,
          color: colors.black100,
          borderRadius: '0.375rem',
          fontWeight: 400,
          height: '3.125rem',
        },
        walletType: {
          height: '6.875rem',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: 'black50',
          minWidth: '100px',
          width: '100%',
          margin: '4px',
          flexGrow: '1',
        },
        cancel: {
          bg: 'red100',
          color: 'white',
        },
      },
    },
  },
};

export const extendedTheme = extendTheme(chakraTheme);

const Theme = ({ children }) => (
  <ChakraProvider theme={extendedTheme}>
    <Fonts />
    {children}
  </ChakraProvider>
);

export default Theme;
