import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box, Flex, Center, Spinner } from '@chakra-ui/react';
import { ROUTE_PATHS } from './constants';
import GridWrapper from '../components/grid-wrapper';
import { GrantProgramBanner } from '../components/banner';
import MembersErrorBoundary from '../members-error-boundary';
const Authenticate = lazy(() => import('./authenticate'));

const BaseHeader = lazy(() => import('../components/base-header'));
const Footer = lazy(() => import('../components/footer'));
const SignupProfile = lazy(() => import('./signup-auth0'));

const Views = lazy(() => import('./views'));

export const createSubrouterPath = basePath => `${basePath}/*`;

export function TopLevelViews() {
  return (
    <BrowserRouter>
      <MembersErrorBoundary variant={MembersErrorBoundary.variants.SKIP_A_BEAT}>
        <Flex width="100%" minHeight="100vh" flexDirection="column" backgroundColor={'#f7ede4'}>
          <Suspense
            fallback={
              <Box h="100vh">
                <Center h="100%">
                  <Spinner />
                </Center>
              </Box>
            }>
            <Box flex="1 0 auto">
              <GrantProgramBanner />
              <Routes>
                <Route
                  path={createSubrouterPath(ROUTE_PATHS.SIGNUP.ROOT)}
                  element={
                    <>
                      <BaseHeader />
                      <SignupProfile />
                    </>
                  }
                />
                <Route path={'/authenticate'} element={<Authenticate />} />
                <Route path="*" element={<Views />} />
              </Routes>
            </Box>
            <GridWrapper border={{ base: 'none', md: '1px solid black' }} mt="7rem" flexShrink="0">
              <Footer />
            </GridWrapper>
          </Suspense>
        </Flex>
      </MembersErrorBoundary>
    </BrowserRouter>
  );
}
